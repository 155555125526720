import React from 'react';

import {Link} from 'gatsby';
import classNames from "classnames";
import {SVGSupportImg} from "../SVGSupportImg";
import {Author} from "../Author";
import {LangLink} from "../LangLink";

import * as styles from './BookMediaObject.module.scss';

export function BookMediaObject({title, slug, coverImage, redirectLink, authors, className, ...otherProps}) {
    const content = <>
        <SVGSupportImg {...coverImage} className={styles.image}/>
        <h1 className={styles.title}>{title}</h1>

        <div className={styles.authors}>
            {authors.map((x, i) => {
                return <Author name={x.name} avatar={x.avatar.localFile.childImageSharp} key={i}/>;
            })}
        </div>
    </>;

    const rootProps = {
        className: classNames(styles.root, className),
        ...otherProps
    };
    if (redirectLink) {
        return <LangLink to={redirectLink} {...rootProps} target="_blank" rel="nofollow, noreferrer">
            {content}
        </LangLink>
    }
    return <LangLink to={`/insights/${slug}`} {...rootProps}>
        {content}
    </LangLink>
}
