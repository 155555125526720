import React from 'react';
import classNames from "classnames";
import {BookMediaObject} from "../BookMediaObject";

import * as styles from './BooksList.module.scss';

export function BooksList({className, books, ...otherProps}) {
    return <div className={classNames(styles.root, className)} {...otherProps}>
        {books.map((x, i) => {
            return <BookMediaObject key={i} {...x} />;
        })}
    </div>
}
